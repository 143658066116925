var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h2',[_vm._v("Teamchallenges")]),_c('v-card',[_c('v-btn',{attrs:{"absolute":"","top":"","right":"","fab":"","small":""},on:{"click":function($event){return _vm.synctc()}}},[_c('span',{staticClass:"fa-stack fa-1x"},[_c('i',{class:{'fa': true,'fa-sync': true,'fa-stack-2x':true,'fa-spin':_vm.sync.tc.in_progress}}),_c('i',{staticClass:"fa fa-bars fa-stack-1x",staticStyle:{"margin-left":"6px","margin-top":"6px","color":"black","font-size":"140%","text-shadow":"0 0 2px white"}})])]),_c('v-data-table',{attrs:{"items":_vm.tcs,"headers":[
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Modus', value: 'mode', sortable: false },
        { text: 'Gender', value: 'gender', sortable: false },
        { text: 'Teams', value: 'teams', sortable: false, align: 'center' },
        { text: 'IDs', value: 'identifiers', sortable: false, align: 'center' },
        { text: 'Löschen', value: 'delete', sortable: false, align: 'center' }
      ],"mobile-breakpoint":0,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.delete",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.del(item._id)}}},[_c('v-icon',[_vm._v(" far fa-trash-alt ")])],1)]}},{key:"item.identifiers",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s((item.identifiers || []).length)+" ")]}},{key:"item.teams",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s((item.teams || []).length)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }